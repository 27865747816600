import React, { useEffect, useContext, useState } from "react";
import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
import "./New_Arrivels.css";
import Tab from "react-bootstrap/Tab";
import Nav_Pills_Holder from "../../Common_Component/Nav_Pills_Holder/Nav_Pills_Holder";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";

import "swiper/css";
import "swiper/css/pagination";
import Product_Swiper from "../../Common_Component/Product_Swiper/Product_Swiper";

import { Context } from "../../../utils/context";

import { GetAllNewArrivals } from "../../../utils/apis/master/Master";

import { Category, Products } from "../../../utils/apis/Product/Product";
import { GetAllShopByCategory } from "../../../utils/apis/master/Master";
import { Link } from "react-router-dom";

function New_Arrivels() {
  const ShopTrendsPiils = [
    { eventKey: "Men", title: "Men" },
    { eventKey: "Women", title: "Women" },
    { eventKey: "Kids", title: "Kids" },
  ];

  const { sellerId, wishlistContext } = useContext(Context);

  const [categoryActive, setActive] = useState("");
  const [categories, setCategory] = useState([]);
  const getCategory = async () => {
    // const res = await GetAllNewArrivals(sellerId);
    const res = await Category(sellerId);
    if (res?.success) {
      setCategory(res?.data);
      getProducts(res?.data?.[0]?.category_id);
      setActive(res?.data?.[0]?.category_id);
      wishlistContext();
    }
  };

  const [products, setProduct] = useState([]);
  const getProducts = async (id) => {
    const res = await Products(id, "", "", sellerId);
    if (res?.success) {
      setActive(id);
      setProduct(res?.data?.data);
    }
  };

  useEffect(() => {
    getCategory();
  }, [sellerId]);

  return (
    <>
      <div className="Shop_Trends New_Arrivels">
        <div className="container">
          <Heading_Holder headingText={"NEW ARRIVALS"} />

          <div className="Shop_Trends_Cards">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <div className="row">
                <div className="col-md-12">
                  <Nav_Pills_Holder
                    tabs={categories}
                    categoryActive={categoryActive}
                    setActive={setActive}
                    getProducts={getProducts}
                  />
                </div>
                <div className="tab_content_holder">
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="Men_Tab_Content">
                        <div className="row">
                          <div className="col-lg-4 col-md-5">
                            <div className="Men_Tab_Content_img_holder main_img_holder">
                            <Link to="/allproductlist">   
                             <Banner_Card
                                imgClassName="Women_Tab_Content_img"
                                imgSource={
                                  process.env.PUBLIC_URL +
                                  "/assets/Home/New_Arrivel/New_Arrivel_Main_Img.png"
                                }
                                overlayClassName={"overlay"}
                               overlayText={"New Arrivals"}
                                overlaySubText={"View All"}
                                background={"#2A2A2A"}
                                padding={"9px 0px"}
                              />
                              </Link>
                            </div>
                          </div>

                          <div className="col-lg-8  col-md-7">
                            <div className="Men_Tab_Content_img_holder">
                              <Product_Swiper
                                sliderImages={products}
                                categoryActive={categoryActive}
                                slidesPerView={3}
                                breakpoints={{
                                  0: { slidesPerView: 2 },
                                  380: { slidesPerView: 2 },
                                  485: { slidesPerView: 2 },
                                  575: { slidesPerView: 2 },
                                  768: { slidesPerView: 2 },
                                  992: { slidesPerView: 3 },
                                  1024: { slidesPerView: 3 },
                                  1200: { slidesPerView: 3 },
                                  1440: { slidesPerView: 3 },
                                  2000: { slidesPerView: 3 },
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    {/* <Tab.Pane eventKey="Women">
                      <div className="Women_Tab_Content">
                        <div className="Men_Tab_Content">
                          <div className="row">
                            <div className="col-lg-4 col-md-5">
                              <div className="Men_Tab_Content_img_holder">
                                <Banner_Card
                                  imgClassName="Women_Tab_Content_img"
                                  imgSource={
                                    process.env.PUBLIC_URL +
                                    "/assets/Home/New_Arrivel/New_Arrivel_Main_Img.png"
                                  }
                                  overlayClassName={"overlay"}
                                  overlayText={"New Arrivals"}
                                  overlaySubText={"View All"}
                                  background={"#2A2A2A"}
                                  padding={"9px 0px"}
                                />
                              </div>
                            </div>

                            <div className="col-lg-8  col-md-7">
                              <div className="Men_Tab_Content_img_holder main_img_holder">
                                <Product_Swiper
                                  sliderImages={sliderWomenImages}
                                  slidesPerView={3}
                                  breakpoints={{
                                    0: { slidesPerView: 2 },
                                    380: { slidesPerView: 2 },
                                    485: { slidesPerView: 2 },
                                    575: { slidesPerView: 2 },
                                    768: { slidesPerView: 2 },
                                    992: { slidesPerView: 3 },
                                    1024: { slidesPerView: 3 },
                                    1200: { slidesPerView: 3 },
                                    1440: { slidesPerView: 3 },
                                    2000: { slidesPerView: 3 },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>

                    <Tab.Pane eventKey="Kids">
                      <div className="Women_Tab_Content">
                        <div className="Men_Tab_Content">
                          <div className="row">
                            <div className="col-lg-4 col-md-5">
                              <div className="Men_Tab_Content_img_holder">
                                <Banner_Card
                                  imgClassName="Women_Tab_Content_img"
                                  imgSource={
                                    process.env.PUBLIC_URL +
                                    "/assets/Home/New_Arrivel/New_Arrivel_Main_Img.png"
                                  }
                                  overlayClassName={"overlay"}
                                  overlayText={"New Arrivals"}
                                  overlaySubText={"View All"}
                                  background={"#2A2A2A"}
                                  padding={"9px 0px"}
                                />
                              </div>
                            </div>

                            <div className="col-lg-8  col-md-7">
                              <div className="Men_Tab_Content_img_holder main_img_holder">
                                <Product_Swiper
                                  sliderImages={sliderKidsImages}
                                  slidesPerView={3}
                                  breakpoints={{
                                    0: { slidesPerView: 2 },
                                    380: { slidesPerView: 2 },
                                    485: { slidesPerView: 2 },
                                    575: { slidesPerView: 2 },
                                    768: { slidesPerView: 2 },
                                    992: { slidesPerView: 3 },
                                    1024: { slidesPerView: 3 },
                                    1200: { slidesPerView: 3 },
                                    1440: { slidesPerView: 3 },
                                    2000: { slidesPerView: 3 },
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane> */}
                  </Tab.Content>
                </div>
              </div>
            </Tab.Container>
          </div>
        </div>
      </div>
    </>
  );
}

export default New_Arrivels;
