import { React, useState, useRef, useEffect, useContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css/pagination";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import "./Category.css";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
} from "@fortawesome/free-solid-svg-icons";

import { GetAllShopByCategory, GetAllShopByCategoryNew } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import { Link, useNavigate } from "react-router-dom";
import { AllSubCategory, Category } from "../../../utils/apis/Product/Product";
// SwiperCore.use([Pagination]);

const Categorys = () => {
  const [swiperInstancenew, setSwiperInstancenew] = useState(null);
  const swiperRef = useRef(null);
  // const handleMainSliderClick = (direction) => {
  //   if (swiperInstancenew) {
  //     if (direction === "prev") {
  //       swiperInstancenew.slidePrev();
  //     } else if (direction === "next") {
  //       swiperInstancenew.slideNext();
  //     }
  //   }
  // };

  const handleSliderClick = (direction) => {
    if (swiperInstancenew) {
      if (direction === "prev") {
        swiperInstancenew.slidePrev();
      } else if (direction === "next") {
        swiperInstancenew.slideNext();
      }
    }
  };

  const { IMG_URL, sellerId } = useContext(Context);
  const [categories, setCategory] = useState([]);
  const getCategory = async () => {
    const res = await Category(sellerId);
    if (res?.success) {
      setCategory(res?.data);
      getSubCategory(res?.data?.[0]?.category_id)
    }
  };

  const [subCategory, setSubCategory] = useState([]);
  const getSubCategory = async (id) => {
    const res = await AllSubCategory(sellerId,id);
    if (res?.success) {
      setSubCategory(res?.data);
    }
  };

  useEffect(() => {
    getCategory();
  }, [sellerId]);

  const navigate = useNavigate();

  return (
    <section className="Category Category_section">
      <div className="container ">
        <div className="row">
          <div className="col-lg-12">
            <div>
              <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row>
                  <Col md={3} lg={2}>
                    <div className="shopCategoryhead">
                      <h2 className="heading">Shop By Category</h2>
                    </div>
                    <Nav variant="pills" className="categoryTabs">
                      {categories?.map((category, index) => (
                        <Nav.Item key={index}>
                          <Nav.Link
                            eventKey={
                              index == 0
                                ? "first"
                                : category?.name
                            }
                            onClick={() => getSubCategory(category?.category_id)}
                          >
                            {category?.name}
                          </Nav.Link>
                        </Nav.Item>
                      ))}
                    </Nav>
                  </Col>
                  <Col md={9} lg={10}>
                    <Tab.Content>
                      {categories?.map((category, index) => (
                        <Tab.Pane
                          onClick={() => {
                            navigate(
                              `/our_products_page/${category?.category_id}`
                            );
                          }}
                          key={category?.category_id}
                          eventKey={String(
                            index == 0
                              ? "first"
                              : category?.name
                          )}
                        >
                          <div className="background-product-detail ">
                            <div className="product-detail">
                              <Swiper
                                slidesPerView={3}
                                spaceBetween={30}
                                navigation={true}
                                pagination={{ clickable: true }}
                                modules={[Navigation, Pagination]}
                                className="mySwiper swiperCategory"
                                ref={swiperRef}
                                onSwiper={(swiper) =>
                                  setSwiperInstancenew(swiper)
                                }
                                breakpoints={{
                                  280: {
                                    slidesPerView: 2,
                                  },
                                  320: {
                                    slidesPerView: 2,
                                  },

                                  420: {
                                    slidesPerView: 3,
                                  },
                                  768: {
                                    slidesPerView: 3,
                                  },
                                  991: {
                                    slidesPerView: 4,
                                  },
                                  1200: {
                                    slidesPerView: 5,
                                  },
                                }}
                              >
                                {subCategory?.map(
                                  (subCategory, imageindex) => (
                                    <SwiperSlide key={imageindex}>
                                      <div>
                                        <div className="imgHovermain">
                                          <Banner_Card
                                            imgSource={
                                              IMG_URL +
                                              subCategory?.image
                                            }
                                            imgClassName="swiperImg"
                                          />
                                        </div>
                                        <div className="text-center cardDEtails">
                                          <h3>
                                            {
                                              subCategory
                                                ?.name
                                            }
                                          </h3>
                                          <p>Explore</p>
                                        </div>
                                      </div>
                                    </SwiperSlide>
                                  )
                                )}
                              </Swiper>
                            </div>
                          </div>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Categorys;
