import React, { useState, useContext, useEffect } from "react";
import "./History.css";
import { Breadcrumb, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Purchased_Items_Modal from "../../../Common_Component/Purchased_Items_Modal/Purchased_Items_Modal";
import { Context } from "../../../../utils/context"
import { orderGet } from "../../../../utils/apis/myAccount/myAccount"
function History() {
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowOne, setModalShowTwo] = React.useState(false);



  const { IMG_URL, sellerId, signin } = useContext(Context);

  const [data, setData] = useState();

  const [orders, setOrder] = useState([]);
  const getOrders = async () => {
    const res = await orderGet(sellerId);
    if (res?.success) {
      setOrder(res?.data);
    }
  }
  const navigate = useNavigate();
  useEffect(() => {
    if(!signin){
      navigate('/');
    }
  }, [signin]);
  useEffect(() => {
    getOrders();
  }, [sellerId]);
  return (
    <>
      <section className="my-account checkout-inner-steps ">
        <div className="container">
          <div className="account-table confirm_order Product_Cart">
            <div className="heading_holder">
              <Breadcrumb>
                <Breadcrumb.Item >
                  <Link to={"/"}>Home</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item >
                  <Link to={"/my-account"}>My account</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item active><Link to={"/order-history"}>Order history</Link></Breadcrumb.Item>
              </Breadcrumb>
            </div>

            <div className="back-text-holder">
              <Link to={"/my-account"}><p>Back</p></Link>
            </div>

            <div className="product_table">
              <Table responsive bordered>
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Order No.</th>
                    <th>Order Date</th>
                    <th>Delivery Date</th>
                    <th>Total</th>
                    <th>Payment Type</th>
                    <th>Payment Status</th>
                    <th>Order Status</th>
                    <th>Order Progress</th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((value, index) => (
                    <tr>
                      <td>{index+1}</td>
                      <td>{value?.invoice_number}</td>
                      <td> {new Date(value?.createdAt).toLocaleDateString('en-US', {
                          day: 'numeric', month: 'short', year: 'numeric'
                      })}</td>
                      <td>-</td>

                      <td>₹ {value?.amount}</td>
                      <td>{value?.payment_mode}</td>
                      <td className={value?.o_w_payment_status?.status === 1 ? "green-text" : "text-danger"}>{value?.o_w_payment_status?.status === 1 ? 'Paid' : 'UnPaid'}</td>
                      <td 
                      className={value?.o_w_order_status?.id === 5 ? "text-danger" : "green-text"}
                      >{value?.o_w_order_status?.name}</td>
                      <td className="view-text" onClick={() => { setData(value); setModalShow(true); }}>View</td>
                    </tr>
                  ))}
                  {/* <tr>
                    <td>2</td>
                    <td>#567865</td>
                    <td>19-09-21</td>
                    <td>21-09-21</td>

                    <td>₹500</td>
                    <td>-</td>
                    <td>Online</td>
                    <td className="red-text">Unpaid</td>
                    <td className="yellow-text">Ordered</td>
                    <td className="view-text" onClick={() => setModalShow(true)}>View</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>#567865</td>
                    <td>19-09-21</td>
                    <td>21-09-21</td>

                    <td>₹500</td>
                    <td>-</td>
                    <td>Online</td>
                    <td className="red-text">Unpaid</td>
                    <td className="cancel-text">Canceled</td>
                    <td className="view-text" onClick={() => setModalShow(true)}>View</td>
                  </tr> */}
                </tbody>
              </Table>

              <div className="span-holder">
                <span>Showing {orders.length} of {orders.length} entries</span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Purchased_Items_Modal
        show={modalShow}
        data={data}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default History;
