import React, { useState, useEffect, useContext } from "react";
import "./Blog.css";
import Card from "react-bootstrap/Card";
import { Col, Row } from "react-bootstrap";
// import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import { Context } from "../../utils/context";
import { useNavigate } from "react-router-dom";
import { getAllBlog } from "../../utils/apis/common/Common";

const Blog = () => {
  const blogPosts = [
    {
      date: "May 03, 2024",
      title: "Mastering the Art of E-commerce: Strategies for Success",
      image: "/assets/blog/img-1.png",
      content:
        "In today's digital age, e-commerce has become an integral part of retail business strategies worldwide. With the convenience of online shopping, consumers are increasingly turning to e-commerce platforms to fulfill their needs. For businesses, this presents a tremendous opportunity to expand their reach and increase revenue. However, succeeding in the competitive landscape of e-commerce...",
    },
    {
      date: "May 03, 2024",
      title: "Unveiling the Future of E-commerce: Trends and Innovations",
      image: "/assets/blog/img-2.png",
      content:
        "In the ever-evolving realm of e-commerce, staying ahead of the curve is not just a competitive advantage; it's a necessity. As technology continues to reshape the way we shop and do business, e-commerce platforms must adapt to meet the changing needs and expectations of consumers...",
    },
    {
      date: "May 03, 2024",
      title:
        "Unveiling the Power of E-Commerce: Revolutionizing the Way We Shop",
      image: "/assets/blog/img-3.png",
      content:
        "In recent years, the landscape of retail has undergone a monumental transformation, thanks to the rise of e-commerce. With the click of a button, consumers now have access to a vast array of products and services from the comfort of their own homes. From everyday essentials to luxury items...",
    },
  ];
  const recentPosts = [
    {
      image: "/assets/blog/recent1.png",
      title: "Boost Sales And Profits",
      date: "May 10, 2024",
    },
    {
      image: "/assets/blog/recent2.png",
      title: "Boost Sales And Profits",
      date: "May 10, 2024",
    },
    {
      image: "/assets/blog/recent3.png",
      title: "Boost Sales And Profits",
      date: "May 10, 2024",
    },
    {
      image: "/assets/blog/recent4.png",
      title: "Boost Sales And Profits",
      date: "May 10, 2024",
    },
    {
      image: "/assets/blog/recent5.png",
      title: "Boost Sales And Profits",
      date: "May 10, 2024",
    },
  ];

  const navigate = useNavigate();
  var HtmlToReactParser = require("html-to-react").Parser;
  var htmlToReactParser = new HtmlToReactParser();
  const [blog, setBlog] = useState();
  const [recentpost, setRecentpost] = useState();
  const { getData, IMG_URL, sellerId } = useContext(Context);

  const getProject = async () => {
    const res = await getData(`/outer-website/blogs?seller_id=${sellerId}`);
    if (res?.success) {
      setBlog(res?.data?.data);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }
  const getProjectTwo = async () => {
    const res = await getData(`/outer-website/blogs?seller_id=${sellerId}`);
    if (res?.success) {
      setRecentpost(res?.data?.recent_post);
    } else {
      console.error('Error fetching  data:', res?.error);
    }
  }

  const BlogDetails = async (id) => {
    // console.log(id, 'hhhhhhhhhhhhhhhhhhhhhhhhhhhhhh');
    navigate(`/blog-details/${id}`)
  }

  useEffect(() => {
    getProject();
    getProjectTwo();
  }, [sellerId]);


  return (
    <>
      <section className="blog">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {blog?.map((post, index) => (
                <Card key={index}>
                  <Row>
                    <Col xs={12} sm={12} md={4} lg={4}>
                      <div className="image-holder">
                        <img
                          src={IMG_URL + post?.image}
                          className="blog-img"
                          alt="Blog"
                        />
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8}>
                      <Card.Body>
                        {/* <Link to="/blog-details"> */}
                        <div className="text-holder">
                          <p>{post?.createdAt}</p>
                          <h3>{post?.name}</h3>
                          <p>{htmlToReactParser.parse(post?.description) || ""}</p>
                          <div className="read-more-align">
                            <p className="readmore-p" type="button" onClick={() => {
                              BlogDetails(post?.id);
                            }}>
                              Read More{" "}
                              {/* <FaArrowRight className="fa-sharp fa-solid fa-arrow-right readmorefafa" /> */}
                            </p>
                          </div>
                        </div>
                        {/* </Link> */}
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              ))}
            </div>
            <div className="col-lg-4">
              <div className="recent-post-div">
                <h6 className="title">Recent Posts</h6>
                <div className="blog-card-holder">
                  {recentpost?.map((post, index) => (
                    <div key={index} className="row mb-3">
                      <div className="col-xxl-3 col-lg-4 col-md-2 col-sm-2 col-3">
                        <div>
                          <img
                            className="recent-post-img"
                            src={IMG_URL + post?.image}
                            alt="Recent Post"
                          />
                        </div>
                      </div>
                      <div className="col-xxl-9 col-lg-8 col-md-10 col-sm-10 col-9">
                        <div className="mt-2">
                          <h6 className="post-title">{post?.name}</h6>
                          <p className="post-date">{post?.createdAt}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;
