import React, { useRef, useState, useEffect, useContext } from "react";
import "./Explore_Brands.css";
import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/grid";
import "swiper/css/pagination";
// import required modules
import { Autoplay, Grid, Pagination } from "swiper/modules";

import { Context } from "../../../utils/context";
import { Brands } from "../../../utils/apis/master/Master";
function Explore_Brands() {
  // Define an array containing image sources
  const imageSources = [
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_1.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_2.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_3.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_4.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_5.png",

    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_1.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_2.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_3.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_4.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_5.png",

    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_1.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_2.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_3.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_4.png",
    "/assets/Home/Explore_ Brands/Explore_ Brands_Img_5.png",
  ];

  const { IMG_URL, sellerId,type } = useContext(Context);
  const [brands, setBrand] = useState([]);
  const getBrand = async () => {
    const res = await Brands(sellerId,type);
    if (res?.success) {
      setBrand(res?.data);
    }
  };

  useEffect(() => {
    getBrand();
  }, [sellerId]);

  return (
    <>
      <div className="Explore_Brands">
        <div className="container">
          <Heading_Holder headingText="EXPLORE BRANDS" />

          <div className="Explore_Brands_Slider_Holder">
            <Swiper
              slidesPerView={6}
              grid={{
                rows: 1,
              }}
              loop={true}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
                reverseDirection: true,
              }}
              modules={[Autoplay]}
              // modules={[Grid, Pagination]}
              className="mySwiper Explore_Brands_Slider"
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                380: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },
                485: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },
                575: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },

                768: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },
                992: {
                  slidesPerView: 6,
                  spaceBetween: 5,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 5,
                },
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 5,
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                2000: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              {brands?.map((imageSource, index) => (
                 index % 2 === 0 && (
                  <SwiperSlide key={index}>
                    <Banner_Card
                      className="Explore_Brands_Slider_Img_holder"
                      imgClassName="Explore_Brands_Slider_Img"
                      imgSource={IMG_URL + imageSource?.image}
                    />
                  </SwiperSlide>
                )
              ))}
            </Swiper>
            <Swiper
              slidesPerView={6}
              grid={{
                rows: 1,
              }}
              spaceBetween={10}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 1000,
                disableOnInteraction: false,
              }}
              loop={true}
              modules={[Autoplay]}
              // modules={[Grid, Pagination]}
              className="mySwiper Explore_Brands_Slider"
              breakpoints={{
                0: {
                  slidesPerView: 2,
                  spaceBetween: 5,
                },
                380: {
                  slidesPerView: 3,
                  spaceBetween: 5,
                },
                485: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },
                575: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },

                768: {
                  slidesPerView: 4,
                  spaceBetween: 5,
                },
                992: {
                  slidesPerView: 6,
                  spaceBetween: 5,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 5,
                },
                1200: {
                  slidesPerView: 5,
                  spaceBetween: 5,
                },
                1440: {
                  slidesPerView: 5,
                  spaceBetween: 10,
                },
                2000: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
              }}
            >
              {brands?.map((imageSource, index) => (
                index % 2 !== 0 && (
                <SwiperSlide key={index}>
                  <Banner_Card
                    className="Explore_Brands_Slider_Img_holder"
                    imgClassName="Explore_Brands_Slider_Img"
                    imgSource={IMG_URL + imageSource?.image}
                  />
                </SwiperSlide>
                )
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </>
  );
}

export default Explore_Brands;
