import React, { useContext, useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fas } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";

import Select from "react-select";
import { RegxExpression } from '../../../utils/common';
import { Errors, Placeholders } from '../../../utils/errors';
import { Context } from '../../../utils/context';

import { pincodeWiseAll, Pincode } from '../../../utils/apis/master/Master';

import { postAddress, EditAddress, getAddress, DeleteAddress } from '../../../utils/apis/orderProcess/orderProcess';
library.add(fas);



const Shipping_information = ({ handleNext, handlePrevious, setAddressSelect, addressSelect }) => {
    const [isSaved, setIsSaved] = useState(false);

    const { Select2Data } = useContext(Context);

    console.log(addressSelect);


    const handleNextStep = async (data) => {
        setAddressSelect(data);
        handleNext();
    }



    const addNewStep = () => {
        setShowInnerPage(true);
    }

    const hanldeEdit = async (data) => {
        reset(data);
        setCities(data?.city?.name);
        setStates(data?.state?.name);
        setCountries(data?.country?.name);
        setValue('pincode_id', {
            value: data.pincode?.id,
            label: data.pincode?.name,
        });
        setValue('address_type', data?.address_type);
        setAddressId(data?.id);
        setShowInnerPage(true);
    }

    const {
        control,
        register,
        handleSubmit,
        getValues,
        setError,
        clearErrors,
        setValue,
        formState: { errors },
        reset,
        trigger,
    } = useForm({
        defaultValues: {
            address_type: 'Home'
        }
    });

    const handlebackPrevious = () => {

        if (showInnerPage) {
            setValue('f_name', "");
            setValue('l_name', "");
            setValue('contact_no', "");
            setValue('address', "");
            setValue('address_type', "Home");
            setValue('land_mark', "");
            setValue('pincode_id', "");
            setAddressId("");
            setCities("");
            setStates("");
            setCountries("");
            clearErrors();
            setIsSaved(true);
            setShowInnerPage(false);
        } else {
            handlePrevious();
        }
    }
    console.log(getValues());

    const onSubmit = async (data) => {


        data.pincode_id = data?.pincode_id?.value;
        var res;
        if (addressId) {
            res = await EditAddress(data, addressId);
        } else {
            res = await postAddress(data);
        }

        if (res?.success) {
            AddressGet();
            setShowInnerPage(false);
            reset();
            setAddressId("");
            setCities("");
            setStates("");
            setCountries("");
        }
    }


    const [addressId, setAddressId] = useState("");
    const [cities, setCities] = useState("");
    const [states, setStates] = useState("");
    const [countries, setCountries] = useState("");
    const [pincodes, setPincodes] = useState([]);

    const handlePincodeChange = async (e) => {
        const res = await Pincode(e);
        if (res?.success) {
            const data = await Select2Data(res.data, "pincode_id", false);
            setPincodes(data);
        }
    };
    const handlePincodeSelect = async (id) => {
        const res = await pincodeWiseAll(id);
        if (res?.success) {
            console.log(res);
            setCities(res.data.city.name);
            setStates(res.data.state.name);
            setCountries(res.data.country.name);

            setValue('city_id', res.data.city_id);
            setValue('state_id', res.data.state_id);
            setValue('country_id', res.data.country_id);
            setValue('pincode_id', {
                value: res.data.id,
                label: res.data.name,
            });
        }
    };

    const [showInnerPage, setShowInnerPage] = useState(false);

    const [address, setAddress] = useState([]);
    const AddressGet = async () => {
        const res = await getAddress();
        if (res?.success) {
            setAddress(res?.data);
        }
    }

    const handleDelete = async (id) => {
            const res = await DeleteAddress(id);
            if(res?.success){
                AddressGet();
            }
    }

    useEffect(() => {

        AddressGet();
    }, []);
    return (
        <>
            <section className='stepp-inner-main'>

                {
                    showInnerPage ? (

                        <div className='formSection'>
                            <Form >
                                <Col xl={7} className="mx-auto mb-3">
                                    <Form.Group controlId="formGridEmail">
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter First Name"
                                            {...register("f_name", {
                                                required: "First name required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.f_name,
                                                "is-valid": getValues("f_name"),
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                        <Form.Control
                                            type="text"
                                            placeholder="Enter Last Name"
                                            {...register("l_name", {
                                                required: "Last name required",
                                            })}
                                            className={classNames("", {
                                                "is-invalid": errors?.l_name,
                                                "is-valid": getValues("l_name"),
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.name.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        />
                                    </Form.Group>

                                    <Form.Group controlId="formGridPassword">
                                        <Form.Label >Contact</Form.Label>
                                        <Form.Control
                                            className={classNames("", {
                                                "is-invalid": errors?.contact_no,
                                                "is-valid": getValues("contact_no"),
                                            })}
                                            type="text"
                                            name="contact_no"
                                            placeholder="Enter Mobile Number"
                                            {...register("contact_no", {
                                                required: "Field is required",
                                                minLength: {
                                                    value: 10,
                                                    message:
                                                        "Number should be at least 10 characters",
                                                },
                                            })}
                                            onKeyDown={(event) => {
                                                if (!RegxExpression.phone.test(event.key)) {
                                                    if (event.key !== "Backspace") {
                                                        event.preventDefault();
                                                    }
                                                }
                                            }}
                                            maxLength={10}
                                        />
                                        {errors?.contact_no && (
                                            <sup className="text-danger">
                                                {errors?.contact_no?.message}
                                            </sup>
                                        )}
                                    </Form.Group>

                                    <Form.Group controlId="formGridAddress1">
                                        <Form.Label>Address</Form.Label>
                                        <div>
                                            <Form.Control
                                                as="textarea"
                                                name="address"
                                                type="text"
                                                rows={3}
                                                placeholder="Enter Message"
                                                {...register("address", {
                                                    required: "Message required",
                                                })}
                                                className={classNames("", {
                                                    "is-invalid": errors?.address,
                                                })}
                                            />

                                        </div>
                                    </Form.Group>

                                    <Row className="mb-3">
                                        <div className='col-lg-6'>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label>City</Form.Label>
                                                <Form.Control
                                                    className={classNames("", {
                                                        "is-invalid": errors?.city,
                                                    })}
                                                    type="text"
                                                    name="city_id"
                                                    readOnly
                                                    value={cities}
                                                    placeholder={Placeholders.city}
                                                />
                                            </Form.Group>

                                        </div>
                                        <div className='col-lg-6'>
                                            <Form.Group as={Col} className='mb-3' controlId="formGridCity">
                                                <Form.Label>PINCODE</Form.Label>
                                                <Controller
                                                    name="pincode_id"
                                                    {...register("pincode_id", {
                                                        required: Errors.pincode,
                                                    })}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <Select
                                                            styles={{
                                                                control: (baseStyles) => ({
                                                                    ...baseStyles,
                                                                    borderColor: errors.pincode_id
                                                                        ? "red"
                                                                        : baseStyles,
                                                                }),
                                                            }}
                                                            {...field}
                                                            options={pincodes}
                                                            onInputChange={(selectedOption) => {
                                                                handlePincodeChange(selectedOption);
                                                            }}
                                                            onChange={(selectedOption) => {
                                                                field.onChange(selectedOption.value);
                                                                handlePincodeSelect(selectedOption.value);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-6'>
                                            <Form.Group as={Col} className='mb-3' controlId="formGridCity">
                                                <Form.Label>Country</Form.Label>
                                                <Form.Control
                                                    className={classNames("", {
                                                        "is-invalid": errors?.country,
                                                    })}
                                                    type="text"
                                                    name="country_id"
                                                    readOnly
                                                    value={countries}
                                                    placeholder={Placeholders.country}
                                                />
                                            </Form.Group>

                                        </div>
                                        <div className='col-lg-6'>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label>STATE</Form.Label>
                                                <Form.Control
                                                    className={classNames("", {
                                                        "is-invalid": errors?.state,
                                                    })}
                                                    type="text"
                                                    name="state_id"
                                                    value={states}
                                                    placeholder={Placeholders.state}
                                                    readOnly
                                                />
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-12'>
                                            <Form.Group as={Col} controlId="formGridCity">
                                                <Form.Label>LANDMARK</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter LandMark"
                                                    {...register("land_mark", {
                                                        required: "LandMark required",
                                                    })}
                                                    className={classNames("", {
                                                        "is-invalid": errors?.land_mark,
                                                        "is-valid": getValues("land_mark"),
                                                    })}

                                                />
                                            </Form.Group>
                                        </div>

                                        <div className='col-lg-12'>
                                            <div className='addressHead'>
                                                <h3>TYPE OF ADDRESS</h3>
                                            </div>
                                            <div className='checkboxfieldd'>
                                                <Form.Group className="addresscheck " id="formGridCheckbox">
                                                    <Form.Check
                                                        type="radio"
                                                        name="addressType"
                                                        label="Home"
                                                        value="Home"
                                                        defaultChecked={
                                                            getValues("address_type") === 'Home'
                                                        }
                                                        {...register("address_type", {
                                                            required: Errors.addressType,
                                                        })}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="addresscheck" id="formGridCheckbox">
                                                    <Form.Check
                                                        type="radio"
                                                        name="addressType"
                                                        label="Work"
                                                        value="Work"
                                                        defaultChecked={
                                                            getValues("address_type") === 'Work'
                                                        }
                                                        {...register("address_type", {
                                                            required: Errors.addressType,
                                                        })}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="addresscheck" id="formGridCheckbox">
                                                    <Form.Check
                                                        type="radio"
                                                        name="addressType"
                                                        label="Office"
                                                        value="Office"
                                                        defaultChecked={
                                                            getValues("address_type") === 'Office'
                                                        }
                                                        {...register("address_type", {
                                                            required: Errors.addressType,
                                                        })}
                                                    />
                                                </Form.Group>
                                            </div>
                                        </div>

                                        <div className='text-center'>
                                            <Button type='button' className="back-btn" onClick={handlebackPrevious}>Back</Button>
                                            <Button className="continue-btn" onClick={handleSubmit(onSubmit)}>
                                                Save
                                            </Button>

                                        </div>

                                    </Row>
                                </Col>






                            </Form>
                        </div>

                    ) : (
                        <div className='address-div'>
                            <div className='title-div'>
                                <h6 className='choss-add'>Choose Address</h6>

                            </div>

                            <div className='row mt-5'>
                                {address?.map((value, index) => (
                                    <div className='col-lg-4 mb-3'>
                                        <div className='addd-card'>
                                            <div className='row'>
                                                <div className='col-10'>
                                                    <div className='card-flex'>
                                                        <div className='loc-img-div'>
                                                            <img className='map-img' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/map.png'} />
                                                            <img className='loc-img' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/location.png'} />
                                                        </div>
                                                        <div className='cont-div'>
                                                            <h6 className='hom'>{value?.address_type}</h6>
                                                            <p className='name'>{value?.f_name} {value?.l_name}</p>
                                                            <p className='adddd'>{value?.address},{value?.land_mark},{value?.city?.name},{value?.state?.name} - {value?.pincode?.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-2 text-end'>
                                                    <Form.Check type="radio" label="" name="address" onClick={() => handleNextStep(value)}
                                                        defaultChecked={addressSelect?.id === value?.id}
                                                    />
                                                    <Link to="" className='edit' onClick={() => hanldeEdit(value)}>Edit</Link>

                                                </div>
                                                <div className='dlttt-div' onClick={() => handleDelete(value?.id)}>
                                                    <FontAwesomeIcon icon="fa-solid fa-trash-can" />
                                                    {/* <span>DELETE ADDRESS</span> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}


                                {/* <div className='col-lg-4 mb-3'>
                                    <div className='addd-card'>
                                        <div className='row'>
                                            <div className='col-10'>
                                                <div className='card-flex'>
                                                    <div className='loc-img-div'>
                                                        <img className='map-img' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/map.png'} />
                                                        <img className='loc-img' src={process.env.PUBLIC_URL + '/assets/checkout-stepform/location.png'} />
                                                    </div>
                                                    <div className='cont-div'>
                                                        <h6 className='hom'>Work</h6>
                                                        <p className='name'>John Doe</p>
                                                        <p className='adddd'>E/92 Apmc Market Yard, Turbhe, Vashi, Mumbai , Maharashtra - 400703</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-2 text-end'>
                                                <Form.Check type="radio" label="" />
                                                <Link to="" className='edit'>Edit</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className='col-lg-4 mb-3'>
                                    <div className='addd-card-new-add'>
                                        <button type='button' className='addnewaddbtn' onClick={addNewStep}>Add New Address</button>
                                    </div>
                                </div>

                            </div>


                        </div>

                    )
                }


                <div className="save-btn-div mt-5">
                    {/* <button type='button' className="back-btn" onClick={handlebackPrevious}>Back</button> */}
                    {/* <button type='button' className="continue-btn" onClick={handleNextStep}>
                        {isSaved ? 'SAVE & NEXT' : 'SAVE'}
                    </button> */}
                </div>
            </section>



        </>
    )
}

export default Shipping_information