// import React from "react";
// import "./ExclusiveCollection.css";
// import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
// import Nav from "react-bootstrap/Nav";
// import Row from "react-bootstrap/Row";
// import Tab from "react-bootstrap/Tab";
// import { Swiper, SwiperSlide } from "swiper/react";

// // Import Swiper styles
// import "swiper/css";
// import "swiper/css/pagination";
// import "swiper/css/navigation";
// import { Pagination, Navigation } from "swiper/modules";
// import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
// const ExclusiveCollection = () => {
//   const imagesContent = [
//     {
//       imgSource:
//         "/assets/Home/Exclusive_Collections/Exclusive_Collections_Img_1.png",
//       text: "Back To Office",
//     },
//     {
//       imgSource:
//         "/assets/Home/Exclusive_Collections/Exclusive_Collections_Img_2.png",
//       text: "Luxury",
//     },
//     {
//       imgSource:
//         "/assets/Home/Exclusive_Collections/Exclusive_Collections_Img_3.png",
//       text: "Must Have",
//     },
//     {
//       imgSource:
//         "/assets/Home/Exclusive_Collections/Exclusive_Collections_Img_4.png",
//       text: "Holiday Cluster",
//     },
//     {
//       imgSource:
//         "/assets/Home/Exclusive_Collections/Exclusive_Collections_Img_5.png",
//       text: "Holiday Cluster",
//     },
//     {
//       imgSource:
//         "/assets/Home/Exclusive_Collections/Exclusive_Collections_Img_2.png",
//       text: "Holiday Cluster",
//     },
//     {
//       imgSource:
//         "/assets/Home/Exclusive_Collections/Exclusive_Collections_Img_4.png",
//       text: "Holiday Cluster",
//     },
//   ];
//   return (
//     <>
//       <section className="ExclusiveCollection">
//         <div className="container">
//           <Heading_Holder headingText={"EXCLUSIVE COLLECTIONS"} />

//           <Tab.Container id="left-tabs-example" defaultActiveKey="1">
//             <Row>
//               <Nav variant="pills" className=" ExclusiveTabs">
//                 <Nav.Item>
//                   <Nav.Link eventKey="1">Men</Nav.Link>
//                 </Nav.Item>
//                 <Nav.Item>
//                   <Nav.Link eventKey="2">Women</Nav.Link>
//                 </Nav.Item>
//               </Nav>

//               <Tab.Content>
//                 <Tab.Pane eventKey="1">
//                   <Swiper
//                     className="exclusiveCollectswiper mySwiper"
//                     slidesPerView={3}
//                     pagination={{ clickable: true }}
//                     modules={[Pagination]}
//                     spaceBetween={30}
//                     breakpoints={{
//                       0: {
//                         slidesPerView: 1,
//                         spaceBetween: 20,
//                       },
//                       485: {
//                         slidesPerView: 2,
//                         spaceBetween: 20,
//                       },
//                       640: {
//                         slidesPerView: 2,
//                         spaceBetween: 20,
//                       },
//                       768: {
//                         slidesPerView: 3,
//                         spaceBetween: 30,
//                       },
//                       1024: {
//                         slidesPerView: 4,
//                         spaceBetween: 40,
//                       },
//                       1200: {
//                         slidesPerView: 4.4,
//                         spaceBetween: 50,
//                       },
//                     }}
//                   >
//                     {imagesContent.map((image, index) => (
//                       <SwiperSlide key={index}>
//                         <div className="exclusiveImgmain">
//                           <Banner_Card
//                             imgClassName="exclusivecardImg"
//                             imgSource={process.env.PUBLIC_URL + image.imgSource}
//                           />

//                           <div className="onImagetxtsec">
//                             <p>{image.text}</p>
//                           </div>
//                         </div>
//                       </SwiperSlide>
//                     ))}
//                   </Swiper>
//                 </Tab.Pane>
//                 <Tab.Pane eventKey="2">
//                   <Swiper
//                     className="exclusiveCollectswiper mySwiper"
//                     pagination={{ clickable: true }}
//                     modules={[Pagination]}
//                     slidesPerView={3}
//                     spaceBetween={30}
//                     breakpoints={{
//                       0: {
//                         slidesPerView: 1,
//                         spaceBetween: 20,
//                       },
//                       485: {
//                         slidesPerView: 2,
//                         spaceBetween: 20,
//                       },
//                       640: {
//                         slidesPerView: 2,
//                         spaceBetween: 20,
//                       },
//                       768: {
//                         slidesPerView: 3,
//                         spaceBetween: 30,
//                       },
//                       1024: {
//                         slidesPerView: 4,
//                         spaceBetween: 40,
//                       },
//                       1200: {
//                         slidesPerView: 4.4,
//                         spaceBetween: 50,
//                       },
//                     }}
//                   >
//                     {imagesContent.map((image, index) => (
//                       <SwiperSlide key={index}>
//                         <div className="exclusiveImgmain">
//                           <Banner_Card
//                             imgClassName="exclusivecardImg"
//                             imgSource={process.env.PUBLIC_URL + image.imgSource}
//                           />

//                           <div className="onImagetxtsec">
//                             <p>{image.text}</p>
//                           </div>
//                         </div>
//                       </SwiperSlide>
//                     ))}
//                   </Swiper>
//                 </Tab.Pane>
//               </Tab.Content>
//             </Row>
//           </Tab.Container>
//         </div>
//       </section>
//     </>
//   );
// };

// export default ExclusiveCollection;

import React, { useState, useEffect, useContext } from "react";
import "./ExclusiveCollection.css";
import Heading_Holder from "../../Common_Component/Heading_Holder/Heading_Holder";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import Banner_Card from "../../Common_Component/Banner_Card/Banner_Card";
import { GetAllExclusiveCollection } from "../../../utils/apis/master/Master";
import { Context } from "../../../utils/context";
import { useNavigate } from "react-router-dom";

const ExclusiveCollection = () => {
  const { sellerId, IMG_URL } = useContext(Context);
  const [exclusive, setExclusive] = useState([]);

  const fetchExclusiveCollection = async () => {
    const response = await GetAllExclusiveCollection(sellerId);
    if (response?.success) {
      setExclusive(response.data);
    }
  };

  useEffect(() => {
    fetchExclusiveCollection();
  }, [sellerId]);
  const navigate = useNavigate();
  return (
    <section className="ExclusiveCollection">
      <div className="container">
        <Heading_Holder headingText="EXCLUSIVE COLLECTIONS" />

        <Tab.Container defaultActiveKey="0">
          <Nav variant="pills" className="ExclusiveTabs">
            {exclusive.map((collection, collectionIndex) => (
              <Nav.Item key={collectionIndex} className="ms-3">
                <Nav.Link eventKey={String(collectionIndex)}>
                  {collection?.category_of_seller?.name}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>

          <Tab.Content>
            {exclusive?.map((collection, collectionIndex) => (
              <Tab.Pane
                key={collectionIndex}
                eventKey={String(collectionIndex)}
              >
                <div>
                  <Swiper
                    className="exclusiveCollectSwiper mySwiper"
                    slidesPerView={3}
                    pagination={{ clickable: true }}
                    modules={[Pagination]}
                    spaceBetween={30}
                    breakpoints={{
                      0: { slidesPerView: 1, spaceBetween: 20 },
                      485: { slidesPerView: 2, spaceBetween: 20 },
                      640: { slidesPerView: 2, spaceBetween: 20 },
                      768: { slidesPerView: 3, spaceBetween: 30 },
                      1024: { slidesPerView: 4, spaceBetween: 40 },
                      1200: { slidesPerView: 4.4, spaceBetween: 50 },
                    }}
                  >
                    {collection.s_sub_categories?.map((image, index) => (
                      <SwiperSlide
                        key={index}
                        onClick={() => {
                          localStorage.setItem("web2_category_id", image?.category_id);
                          localStorage.setItem("web2_sub_category_id", image?.subcategory_of_seller?.sub_category_id);
                          navigate(`/our_products_page/${image?.category_id}`);
                        }}
                      >
                        <div className="exclusiveImgmain">
                          <Banner_Card
                            imgClassName="exclusivecardImg"
                            imgSource={IMG_URL + image?.subcategory_of_seller?.image}
                          />

                          <div className="onImagetxtsec">
                            <p>{image?.subcategory_of_seller?.name}</p>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </div>
    </section >
  );
};

export default ExclusiveCollection;
