import React, { useContext } from "react";
import "./Grand_Total_Table.css";
import { Button, Form, Table } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Buttons from "../../Common_Component/Buttons/Buttons";
import { faClipboard, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Heading_Holder from "../Heading_Holder/Heading_Holder";

import { Context } from "../../../utils/context";

function Grand_Total_Table({ cart, totalPrice, discount, totalTax }) {

  const { signin } = useContext(Context);

  const navigate = useNavigate();
  return (
    <>
      <div className="Grand_Total_Table ">
        <div className="container">
          <div className="Heading_text_Holder">
            <Heading_Holder
              headingText={
                <>
                  <FontAwesomeIcon
                    className="Cart_icon"
                    icon={faClipboardList}

                  />
                  Order Summary
                </>
              }
            />
          </div>
        </div>
      </div>
      <div className="Grand_Total_Table">
        <div className="grand_total_table">

          <Table responsive>
            <thead>
              <tr className="top-border">
                <th>Total amount</th>
                <td>₹ {totalPrice}</td>
              </tr>
            </thead>
            <thead>
              <tr className="top-border">
                <th>Discount</th>
                <td>₹ {discount}</td>
              </tr>
            </thead>

            <thead>
              <tr className="top-border">
                <th>Tax</th>
                <td>₹ {totalTax}</td>
              </tr>
            </thead>
            <thead>
              <tr>
                <th>Shipping</th>
                <td>₹0</td>
              </tr>
            </thead>

            <thead>
              <tr className="bottom-border">
                <th className="bold-text">Total</th>
                <td className="bold-text">₹ {(totalPrice - discount) + totalTax}</td>
              </tr>
            </thead>
          </Table>
        </div>
        <div className="button-holder">
          {signin ? (
            <div onClick={() => navigate("/checkout-form")}>
              <Buttons
                className="procheckout_btn"
                text={` PROCEED TO CHECKOUT (${cart.length})`}
              />
            </div>
          ) : (
            <Link to={"/login"}>
              <Buttons
                className="procheckout_btn"
                text={` PROCEED TO CHECKOUT (${cart.length})`}
              />
            </Link>
          )}

        </div>
      </div>
    </>
  );
}

export default Grand_Total_Table;
