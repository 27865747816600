import React, { useState, useEffect, useContext } from "react";
import "./Reviews.css";
import { Rating } from "react-simple-star-rating";
import {
  postReview,
  getReview,
  productReviewOnlyOnce,
} from "../../../utils/apis/Product/Product";
import { useForm } from "react-hook-form";
import Form from "react-bootstrap/Form";
import Success_Modalss from "../../Common_Component/Success_Modal/Success_Modalss";
import { Context } from "../../../utils/context";

const Reviews = ({ id, sellerId }) => {
  const [rating, setRating] = useState(1);

  const [reviews, setReviews] = useState([]);

  const [modalShow, setModalShow] = React.useState(false);

  const { signin } = useContext(Context);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm();

  const onSubmit = async (data) => {
    try {
      data.product_id = id;
      data.seller_id = sellerId;
      data.rating = rating;

      const res = await postReview(data);
      if (res?.success) {
        reset();

        setRating(0);
        setModalShow(true);
        setTimeout(() => {
          getReviews();
          getUserReview();
          setModalShow(false);
        }, 3000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getReviews = async () => {
    try {
      const response = await getReview(id);
      if (response?.success) {
        setReviews(response.data.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const [userreview, setUserReviews] = useState(false);
  const getUserReview = async () => {
    const res = await productReviewOnlyOnce(id, sellerId);

    setUserReviews(res?.data?.hasReviewed);
  };

  useEffect(() => {
    getReviews();
    getUserReview();
  }, []);

  return (
    <>
      <section className="Reviews">
        <div className="container">
          <div className="row RatingmainRow">
            <Form className="col-lg-5" onSubmit={handleSubmit(onSubmit)}>
              {!userreview && signin && (
                <>
                  <div className="ratingStarsec">
                    <Rating
                      onClick={(rate) => setRating(rate)}
                      ratingValue={rating}
                      size={"25px"}
                    />
                    <p>Rating ({rating}/5)</p>
                  </div>

                  <div className="whatYoulikesec">
                    <h3>What did you like?</h3>
                    <Form.Group>
                      <textarea
                        {...register("name", { required: "Name is required" })}
                        rows={5}
                        className="textareaRating"
                        placeholder={
                          "Tell us more (not more than 700 characters)"
                        }
                      />
                    </Form.Group>
                  </div>

                  <div className="ratingbtnsec">
                    <button onClick={async () => {
                      await setRating(1);
                      setValue('name', "");
                    }
                    } className="cancelbtn">
                      Cancel
                    </button>

                    <button className="SubmitBtn" type="submit">
                      Post
                    </button>
                  </div>
                </>
              )}
            </Form>

            <div className="col-lg-7">
              <div className="ReviewsContentsec">
                {reviews.length > 0 && (
                  <h3>Top 3 Reviews</h3>
                )}

                {reviews.map((review, index) => (
                  <div className="specificReviews" key={index}>
                    <h3>
                      {review.o_w_user.first_name} {review.o_w_user.last_name}
                    </h3>
                    <p>{review.name}</p>
                    <div className="ratingDatesec">
                      <Rating
                        initialValue={review.rating}
                        SVGclassName="star-svg"
                        fillColor="#1D44AD"
                        size={"15px"}
                        readonly={true}
                      />
                      <p>{new Date(review.createdAt).toLocaleDateString()}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Success_Modalss
        texts=" Review added successfully "
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
};

export default Reviews;
