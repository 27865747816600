import React, { useContext, useEffect, useState } from "react";
import "./Style_On.css";
import Select_Drop from "../../Common_Component/Select_Drop/Select_Drop";
import Buttons from "../../Common_Component/Buttons/Buttons";

import { Context } from "../../../utils/context";
import { Category } from "../../../utils/apis/Product/Product";
import { GetAllShopByCategory } from "../../../utils/apis/master/Master";
import { useNavigate } from "react-router-dom";
function Style_On() {
  // Define options for each Select_Drop component
  const shoppingForOptions = [
    { value: "1", label: "MEN" },
    { value: "2", label: "FEMALE" },
    { value: "3", label: "KIDS" },
  ];

  const goingToOptions = [
    { value: "1", label: "WEDDING" },
    { value: "2", label: "OFFICE / MEETING" },
    { value: "3", label: "SPORTS / OUTING" },
    { value: "4", label: "CASUAL OUTING" },
    { value: "5", label: "PARTY" },
  ];

  const preferOptions = [
    { value: "1", label: "ETHNIC" },
    { value: "2", label: "CEREMONIAL" },
    { value: "3", label: "CASUAL" },
    { value: "4", label: "PARTY" },
  ];

  const { Select2Data, sellerId } = useContext(Context);
  const [category, setCategory] = useState([]);
  const getCategory = async () => {
    const res = await Category(sellerId);
    if (res?.success) {
      // var data = await Select2Data(res?.data, "category_id", false);
      setCategory(res.data);
      localStorage.setItem("web2_category_id", res?.data?.[0]?.category_id);
      setCategoryId(res?.data?.[0]?.category_id);
    }
  };

  useEffect(() => {
    getCategory();
  }, [sellerId]);

  const navigate = useNavigate();
  const [categoryId, setCategoryId] = useState("");
  const handleChnage = async () => {
    navigate(`/our_products_page/${categoryId}`);
  };
  return (
    <>
      <div className="Style_On">
        <div className="container">
          <div className="Style_On_Drop_Holder">
            <div className="row">
              <div className="col-md-6 col-lg-3">
                <div className="select_drop_holder">
                  <Select_Drop
                    labelText="SHOPPING FOR"
                    optionsSelect={category}
                    setCategoryId={setCategoryId}
                  />
                </div>
              </div>

              {/* <div className="col-md-6 col-lg-3">
                <div className="select_drop_holder">
                  <Select_Drop
                    labelText="GOING TO"
                    optionsSelect={goingToOptions}
                  />
                </div>
              </div>

              <div className="col-md-6 col-lg-3">
                <div className="select_drop_holder">
                  <Select_Drop
                    labelText="PREFER"
                    optionsSelect={preferOptions}
                  />
                </div>
              </div> */}

              <div className="col-md-6 col-lg-3">
                <div className="select_drop_holder">
                  <div className="style-btn-holder  ">
                    <Buttons
                      className={"style-btn"}
                      onClick={handleChnage}
                      text="Filter"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Style_On;
