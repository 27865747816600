import { React, useContext, useEffect, useState } from "react";
import "./WishList.css";
import Banner_Card from "../Common_Component/Banner_Card/Banner_Card";
import Product_Details_Div from "../Common_Component/Product_Details_Div/Product_Details_Div";
import Color_Cart_Holder from "../Common_Component/Color_Cart_Holder/Color_Cart_Holder";
import Heart_Component from "../Common_Component/Heart_Component/Heart_Component";
import BreadcrumComponent from "../Common_Component/BreadcrumComponent/BreadcrumComponent";
import Pagenation from "../Common_Component/Pagenation/Pagenation";
import { Link, useNavigate } from "react-router-dom";
import Buttons from "../Common_Component/Buttons/Buttons";
import { Context } from "../../utils/context";
import { AddToCart } from "../../utils/apis/addToCart/addToCart";
import { getWishlistProducts } from "../../utils/apis/Wishlist/Wishlist";

function WishList() {
  const {
    IMG_URL,
    shimmerLoader,
    setShimmerLoader,
    wishlistData,
    wishlistContext,
    sellerId,
    signin
  } = useContext(Context);

  const navigate = useNavigate();
  const [products, setProducts] = useState([]); 
  const getList = async () => {
    const res = await getWishlistProducts();
    setProducts(res?.data);
  };

  const handleWishCart = async () => {
    // await Promise.all(
    //   wishlistData?.map(async (value) => {
    //     await AddToCart(value.seller_id);
    //   })
    // );
    // setModalShow(true);
    // setTimeout(() => {
    //   // setModalShow(false);
    //   // navigate("/product-cart");
    // }, 3000);
  };
  useEffect(() => {
    if(signin){
      getList();
    }
    
  }, []);

  const breadcrumbItems = [
    { text: "Home", link: "/" },
    { text: "Wishlist", className: "active" },
  ];
  return (
    <>

      {products.length === 0 ? (
        <>
          {/* empty cart section start */}
          < section className="Empty_Cart">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-xl-3 col-lg-5 col-md-6 col-sm-7 col-10">
                  <div className="shop-now-content">
                    <div className="img-holder">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Product Description/basket.png"
                        }
                        className="basket_img"
                      />
                    </div>
                    <div className="heading_holder mt-3">
                      <h5>Your wishlist is Empty</h5>
                      <p>
                        You have no items in your shopping wishlist. Let's go add
                        something
                      </p>
                      <div className="main-btn-div">
                        <Link to={"/"}>
                          <button className="back-btn" type="button">Add Now</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section >
          {/* empty cart section end */}
        </>
      ) : (
        <>
          <div className="WishList">
            <div className="container">
              <div className="mb-4">
                <BreadcrumComponent items={breadcrumbItems} />
              </div>
              <div className="WishList_Card_Holder">
                <div className="row">
                  {products?.map((product, index) => (
                    <div
                      key={index}
                      className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-6 col-6"

                    >
                      <Banner_Card
                        product={product}
                        imgClassName="Tab_Slider_Img"
                        // detailsLink={product.detailsLink}
                        imgSource={IMG_URL + product?.thumbnail}
                        heartClassName={"Heart_Like"}
                        HeartLikeComponent={
                          <Heart_Component getList={getList} id={product?.id} />
                        }
                        ProductDetailsClassName={"Product_Details"}
                        ProductDetailsSection={
                          <Product_Details_Div
                            product={product}
                            // ColorCartHolder={<Color_Cart_Holder />}
                            subheading={product?.product_title}
                            spanText={product?.product_generic_name}
                            // productDescription={product.productDescription}
                            Price={product?.mrp}
                            subPrice={product?.selling_price}
                          />
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="add_all_btn">
                <Link to={"/product-cart-inner"}>
                  {/* <Buttons
                onClick={handleWishCart}
                className="SubmitBtn"
                text="Add all to cart"
              /> */}
                </Link>
              </div>

              {/* <Pagenation /> */}
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default WishList;
