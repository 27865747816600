import {
  getData,
  postData,
  putData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
} from "../../api";

export const addWishlist = async (id, seller_id) => {
  try {
    return await postData(`/outer-website/wishlist/add`, {
      product_id: id,
      seller_id: seller_id,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getWishlist = async () => {
  try {
    return await getData(`/outer-website/wishlist/show`);
  } catch (error) {
    console.log(error);
  }
};

export const getWishlistProducts = async () => {
  try {
    return await getData(`/outer-website/wishlist/show-products`);
  } catch (error) {
    console.log(error);
  }
};
