import React, { useState, useEffect, useContext } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Buttons from "../../Common_Component/Buttons/Buttons";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Login from "../Login";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Context } from "../../../utils/context";
import Cookies from "js-cookie";
import { userRegister } from "../../../utils/apis/login/Login";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import classNames from "classnames";
import { RegxExpression } from "../../../utils/common";
import PasswordChecklist from "react-password-checklist";

function Register() {
  const navigate = useNavigate();
  const [showLogin, setShowLogin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);



  const handleLoginClick = () => {
    setShowLogin(true);
  };

  const { signin, setSignin, sellerId } = useContext(Context);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
    reset,
    trigger,
  } = useForm();

  const onSubmit = async (data) => {

    if(data.password === data.re_password){
      data.seller_id = sellerId;
      const res = await userRegister(data);
      if (res?.success) {
        await Cookies.set("outer_website_security", res?.data?.token);
        setSignin(true);
        reset();
        navigate("/");
        // await loginCart();
        // setModalShow(true);
        // setTimeout(() => {
        //   setModalShow(false);
        //   navigate('/');
        // }, 3000);
      } else {
        if (res?.message?.both) {
          setError("email", { message: res?.message?.both });
          setError("contact_no", { message: "" });
        }
        if (res?.message?.email) {
          setError("email", { message: res?.message?.email });
        }
        if (res?.message?.contact_no) {
          setError("contact_no", { message: res?.message?.contact_no });
        }
      }
    }else{
      setError("password",{message:"Password does not match"});
    }
    
  };

  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  return (
    <>
      {showLogin ? (
        <Login />
      ) : (
        <section className="register-section">
          <Container>
            <div className="LogIn">
              <div className="row bg-color me-0 ms-0">
                <div className="col-lg-7 p-0">
                  <div className="row justify-content-center border-bottom-color me-0 ms-0  ">
                    <div className="loginlogo-div">
                      <img
                        className="loginlogo"
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/Websitelogo/netpurti_logo.png"
                        }
                        alt="social_img"
                      />
                    </div>

                    <div className="col-xl-8 col-lg-7 col-md-7 ">
                      <div className="back_to_span">
                        <Link to={"/"}>
                          <p>Back to Home</p>
                        </Link>
                      </div>

                      <div className="heading-holder">
                        <h4>Register</h4>
                      </div>

                      <div className="log_in_form">
                        <Form onSubmit={handleSubmit(onSubmit)}>
                          <Form.Group className="mb-3" controlId="">
                            <Form.Control
                              type="text"
                              name="First_Name"
                              placeholder="Enter First Name"
                              {...register("first_name", {
                                required: "First name required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.first_name,
                                "is-valid": getValues("first_name"),
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.name.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {errors?.first_name && (
                              <sup className="text-danger">
                                {errors?.first_name?.message}
                              </sup>
                            )}
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="">
                            <Form.Control
                              type="text"
                              name="last_Name"
                              placeholder="Enter Last Name"
                              {...register("last_Name", {
                                required: "First name required",
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.last_Name,
                                "is-valid": getValues("last_Name"),
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.name.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                            />
                            {errors?.last_Name && (
                              <sup className="text-danger">
                                {errors?.last_Name?.message}
                              </sup>
                            )}
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="">
                            <Form.Control
                              type="text"
                              name="email"
                              placeholder="Enter Email"
                              {...register("email", {
                                required: "Email is required",
                                pattern: {
                                  value: RegxExpression.email,
                                  message: "Invalid email address",
                                },
                              })}
                              className={classNames("", {
                                "is-invalid": errors?.email,
                                "is-valid": getValues("email"),
                              })}
                            />
                            {errors?.email && (
                              <sup className="text-danger">
                                {errors?.email?.message}
                              </sup>
                            )}

                            {/* <div
                              style={{
                                position: "absolute",
                                top: "50%",
                                right: "10px",
                                transform: "translateY(-50%)",
                              }}
                            >
                              <span
                                style={{ color: "green", fontSize: "1.2em" }}
                              >
                                ✔
                              </span>
                            </div> */}
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="">
                            <Form.Control
                              className={classNames("", {
                                "is-invalid": errors?.contact_no,
                                "is-valid": getValues("contact_no"),
                              })}
                              type="text"
                              name="contact_no"
                              placeholder="Enter Mobile Number"
                              {...register("contact_no", {
                                required: "Field is required",
                                minLength: {
                                  value: 10,
                                  message:
                                    "Number should be at least 10 characters",
                                },
                              })}
                              onKeyDown={(event) => {
                                if (!RegxExpression.phone.test(event.key)) {
                                  if (event.key !== "Backspace") {
                                    event.preventDefault();
                                  }
                                }
                              }}
                              maxLength={10}
                            />
                          </Form.Group>
                          {errors?.contact_no && (
                            <sup className="text-danger">
                              {errors?.contact_no?.message}
                            </sup>
                          )}
                          <Form.Group className="mb-3" controlId="">
                            <div className="password-field-holder">
                              <Form.Control
                                type={showPassword ? "text" : "password"}
                                placeholder="Enter Password*"
                                name="password"
                                {...register("password", {
                                  required: "Password is required",
                                  minLength: {
                                    value: 8,
                                    message:
                                      "Password must be at least 8 characters long",
                                  },
                                  pattern: {
                                    value:
                                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                                    message:
                                      "Password must contain at least one uppercase letter, one lowercase letter, and one number",
                                  },
                                })}
                                onChange={(e) => setPassword(e.target.value)}
                              />

                              <div className="eye-icon-holder ">
                                <FontAwesomeIcon
                                  icon={showPassword ? "eye" : "eye-slash"}
                                  className="eye-icon"
                                  onClick={() => setShowPassword(!showPassword)}
                                />
                              </div>
                            </div>
                            {errors?.password && (
                              <sup className="text-danger">
                                {errors?.password?.message}
                              </sup>
                            )}
                          </Form.Group>

                          <Form.Group className="mb-3" controlId="">
                            <div className="password-field-holder">
                              <Form.Control
                                type={showPasswordOne ? "text" : "password"}
                                placeholder="Re-Enter Password*"
                                name="re_password"
                                {...register("re_password", {
                                  required: "Re-Password is required",
                                })}
                                onChange={(e) =>
                                  setPasswordAgain(e.target.value)
                                }
                              />
                              <div className="eye-icon-holder ">
                                <FontAwesomeIcon
                                  icon={showPasswordOne ? "eye-slash" : "eye"}
                                  className="eye-icon"
                                  onClick={() => setShowPasswordOne(!showPasswordOne)}
                                />
                              </div>
                            </div>
                            {errors?.re_password && (
                              <sup className="text-danger">
                                {errors?.re_password?.message}
                              </sup>
                            )}
                          </Form.Group>
                          {password && (
                            <PasswordChecklist
                              rules={[
                                "minLength",
                                "specialChar",
                                "number",
                                "capital",
                                "match",
                              ]}
                              minLength={8}
                              value={password}
                              valueAgain={passwordAgain}
                              onChange={(isValid) => { }}
                              style={{
                                fontSize: "10px",
                                padding: "4px",
                                margin: "0",
                              }}
                            />
                          )}

                          <div className="btn-holder">
                            <button
                              className="SubmitBtn"
                              type="button"
                              onClick={handleSubmit(onSubmit)}
                            >
                              Create Account
                            </button>
                          </div>
                        </Form>

                        <div className="create_account mb-5">
                          <div className="login_text">
                            <p>
                              Already have an account?{" "}
                              {/* <Link to={"/login"}> */}
                              <span
                                className="login_text"
                                onClick={handleLoginClick}
                              >
                                LOGIN
                              </span>
                              {/* </Link> */}
                            </p>
                          </div>
                        </div>

                        <div className="log_in_with">
                          <p>or login with netpurti</p>
                        </div>

                        <div className="social_img_holder">
                          <img
                            className="social_img"
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/Websitelogo/loginlogo.png"
                            }
                            alt="social_img"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </section>
      )}
    </>
  );
}

export default Register;
